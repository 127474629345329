/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "./assets/variable.scss";
@import "./assets//vector-map.scss";

// *{
//   scrollbar-color: $border-color #ffffff;
//   scrollbar-width: thin;
//   scrollbar-gutter: stable;
// }

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  cursor: pointer !important;
}
// Customize TextArea ScrollBar
textarea::-webkit-scrollbar {
  width: 12px;
}
textarea::-webkit-scrollbar-track {
  margin-top: 5px; 
  margin-bottom: 5px;
  border-radius: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: $theme-white;
}
.scrollbar-width-none{
  scrollbar-width: none;
}
/* Handle */
*::-webkit-scrollbar-thumb {
  background: $border-color;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
  background: $border-color;
}

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

::-ms-reveal {
  display: none;
}

.p-l-40 {
  padding-left: 40px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-18 {
  padding-right: 18px;
}

.p-r-14 {
  padding-right: 14px !important;
}

.p-l-14 {
  padding-left: 14px !important;
}

.min-w-35p {
  min-width: 35px;
}

.width-40px {
  width: 40px !important;
}

.width-150px {
  width: 150px !important;
}

.width-115px {
  width: 115px !important;
}

.width-8_5rem {
  width: 8.5rem;
}

.width-8rem {
  width: 8rem;
}


.border-transparent {
  border: 1px solid transparent !important;
}

.border-right-grey {
  border-right: 1px solid $border-light-gray;
}

.border {
  border: 1px solid $border-light-gray;
}

.border-right {
  border-right: 1px solid $border-light-gray;
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(./assets/fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Semi-Bold";
  src: local("Poppins-Semi-Bold"),
    url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(./assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-MediumItalic";
  src: local("Poppins-MediumItalic"),
    url(./assets/fonts/Poppins/Poppins-MediumItalic.ttf) format("truetype");
}

input::placeholder {
  font-size: $font-9 !important;
  color: $theme-input-placeholder !important;
  font-family: "Poppins-Regular";
}

.filterDropdown {
  input::placeholder {
    font-size: $font-12 !important;
    color: $theme-white !important;
    font-family: "Poppins-Medium";
    margin: 12% 0% 9% !important;
  }
}

.bigFilterDropdown {
  .select-wrapper {
    .btn-intent {
      height: 34px !important;
    }

    input::placeholder {
      font-size: $font-12;
    }
  }
}
.two-line-truncate{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
.three-dots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer !important;
}

.pe-none {
  pointer-events: none;
}

.cursor-notAllowed {
  cursor: not-allowed !important;
}

.assign_btn_disable{
  color:$theme-primary-grey !important;
  pointer-events: none;
  opacity: 50%;
}
// layout css

//font-family utility classes start 
.font-semi-bold {
  font-family: "Poppins-Semi-Bold";
}

.font-Regular {
  font-family: "Poppins-Regular" !important;
  letter-spacing: normal !important;
}

.font-Medium {
  font-family: "Poppins-Medium" !important;
}

.font-bold {
  font-family: "Poppins-Bold";
}
.font-Medium-italic {
  font-family: "Poppins-MediumItalic";
}

//font-family utility classes end 

//font-color utility classes start
.color-primary-grey {
  color: $theme-primary-grey
}

.color-primary-darkgrey {
  color: $theme-input-placeholder;
}

.color-red {
  color: $theme-primary-orange-red;
}

.color-primary {
  color: $theme-primary-blue;
}
.color-light-blue {
  color: $border-light-blue !important;
}

.border-red {
  border: 1px solid #F0A997 !important;
}

.border-blue {
  border: 1px solid #82BDDE !important;
}

.border-grey {
  border: 1px solid #B9B9B9 !important;
}

.color-greylight {
  color: #B9B9B9;
}
.color-theme-forest-green {
  color: $theme-forest-green;
}

//font-color utility classes end

// font size utility classes start

.f-11 {
  font-size: $font-11;
}

.f-8 {
  font-size: $font-8;
}
.f-9 {
  font-size: $font-9;
}
.f-10 {
  font-size: $font-10;
}

.f-12 {
  font-size: $font-12 !important;
}

.f-13 {
  font-size: $font-13 !important;
}

.f-14 {
  font-size: $font-14 !important;
}

.f-20 {
  font-size: $font-20 !important;
}

// font size utility classes end

// height or width utility classes start 
.h-0 {
  height: 0;
}

.width-webkiet-fill-available{
  width: -webkit-fill-available;
}
// height or width utility classes end 


.page-wrapper.compact-wrapper {
  .page-header {
    margin-left: 235px;
    width: calc(100% - 235px);
    box-shadow: 0px 0px 10px rgba(39, 32, 120, 0.1);

    .header-wrapper {
      padding: 20px 30px;

      .notification-box {
        .badge {
          position: absolute;
          padding: 2px 2px;
          background-color: $theme-white !important;
          border: 1px solid $theme-primary-blue;
          color: $theme-primary-blue;
          border-radius: 50%;
          height: 17px;
          width: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px !important;
        }
      }
    }
  }

  .page-body-wrapper .page-body {
    margin-left: 235px;
    background-color: $theme-white;
  }

  .page-body-wrapper {
    .according-menu {
      top: 16px !important;
    }
  }

  .footer {
    margin-left: 235px;
  }
}

// For Incomplete Profile and Header Design < 767px 
@media (max-width: 954px) {
  .page-header{
      transition: 0.3s !important;
      &.showIncompleteProfileDiv{
          margin-top: 45px !important;
      } }
  .warning-header{
      height: 55px !important ;
      .title{
        font-size: $font-12 !important;
    }
    .middle,.end{
        font-size: $font-8 !important;
    }
  }
}
// 
// For User Setting Region
@media only screen and (max-width: 767px) {
  .user-setting {
    display: flex;
    flex-direction: column; 
    align-items: flex-start !important; 
    .user-responsive{
      display: flex;
      flex-direction: row !important;
    }.middle-line {
      display: none !important;
    }.user-organization {
      margin-top: 5px; 
    }
  }
}
@media only screen and (max-width: 568px) {
  .user-details-recieve-text-one{
    display: none !important;
  }
  .user-details-recieve-text-two{
    display: block !important;
    left: 49%;
    bottom: 25px;
    width: 60%;
  }
}
// For User Setting Region End

@media only screen and (max-width: 767px) {
  .page-header .notification-box {
    .badge {
    position: absolute;
    border-radius: 50%;
    height: 15px !important;
    width: 15px!important;
    top: -4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px !important;
    }
  }
}


@media only screen and (max-width: 991.98px) {
.um-dropdown-div{
  flex-wrap: nowrap !important;
}
.show-inactive{
  align-items: start !important;
}

  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 80px);
    height: calc(100vh - 80px);
    margin-top: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 65px);
    height: calc(100vh - 65px);
    margin-top: 65px;
    overflow-x: hidden;
    padding: 0px 8px 0px 8px !important
  }

  .page-wrapper .page-header .header-wrapper .nav-right ul li svg {
    width: 24px;
  }

  .update-apoointment-record {
    .modal-dialog {
      max-width: 520px !important;
    }

  }

  // .filter-popup {
  //   .modal-dialog {
  //     max-width: 550px !important;
  //     min-height: calc(100% - (-383px)) !important;
  //   }
  // }

  .width-8_5rem {
    width: 4.5rem;
  }

}

// layout css

//modal center 
.modal-dialog {
  margin-right: auto;
  margin-left: auto;
}

//end

// #region sidebar scss

@media (max-width:991.98px) {
  .sidebar-wrapper{
    .logo-wrapper{
      visibility: hidden;
    }
  }
 
}

.sidebar-wrapper {
  width: 235px !important;

  .logo-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 !important;
  }

  .sidebar-links li a,
  .sidebar-submenu li a {
    font-family: "Poppins-Semi-Bold" !important;
    font-size: $font-13 !important;
    letter-spacing: 0.4px !important;
    color: $theme-primary-grey !important;

    span {
      font-family: "Poppins-Semi-Bold" !important;
      font-size: $font-13;
      letter-spacing: 0.4px !important;
      color: $theme-primary-grey !important;
    }
  }

  li .sidebar-link.active {
    background: linear-gradient(100deg, rgba(4, 123, 188, 0.2) 0%, rgba(4, 123, 188, 0.2) 50%, rgba(4, 123, 188, 0.2) 100%);
  }

  li .sidebar-link.active span {
    color: $theme-primary-blue !important;
  }

  li .sidebar-link.active::after {
    background: $theme-primary-blue !important;
  }

  li .sidebar-link.active .according-menu i {
    color: $theme-primary-blue !important;
  }

  li .sidebar-submenu li a.active {
    color: $theme-primary-blue !important;
  }

  li .sidebar-submenu li a.active::before {
    visibility: hidden !important;
  }

  li .sidebar-submenu li a:hover {
    color: $theme-primary-grey !important;
  }

  li .sidebar-submenu li a.active:hover {
    color: $theme-primary-blue !important;
  }
}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content>li .sidebar-link.active~.sidebar-submenu::before {
  height: calc(100% - 7.5px);
  transition: height 0.5s;
}

// //***ngx datatable***

// for Applying width to different page ngx-dataTable because of there responsive bg-color

#Internal_User_LIst ngx-datatable .datatable-scroll,
#User_Roles ngx-datatable .datatable-scroll,
#User_List ngx-datatable .datatable-scroll,
.ngx-datatable-auditLog ngx-datatable .datatable-scroll {
  width : 100% !important
}
@media (max-width :1570px){
  #Internal_User_LIst ngx-datatable .datatable-scroll,
  #User_Roles ngx-datatable .datatable-scroll,
  #User_List ngx-datatable .datatable-scroll,
  .ngx-datatable-auditLog ngx-datatable .datatable-scroll {
    width : inherit !important
  }
}

.ngxDatatable-reports {
  .ngx-datatable.bootstrap.expandable {
    .visible {
      .datatable-scroll {
        width : inherit !important ;
      }

      .datatable-row-wrapper {
        width: auto !important;

        .datatable-body-row {
          border-bottom: 1px solid transparent !important
        }

        .datatable-summary-row {
          border: 1px solid $theme-primary-grey !important;

          .datatable-body-cell-label {
            height: 100%;
          }
        }
      }
    }
  }
}

.ngx-datatable.bootstrap.expandable {
  .visible {
    .datatable-body-row {
      border-bottom: 1px solid $border-color !important
    }
  }
}

.ngx-datatable.bootstrap {
  height: 100%;
  display: flex;
  flex-direction: column;

  .datatable-group-header {
    border-bottom: 1px solid $border-color;
    height: $row-height;
    width: auto !important;

    >* {
      height: $row-height;
    }

    .group-header-wrapper {
      display: flex;
      align-items: center;
      width: auto !important;

      >div {
        padding: 0 15px 0 15px;
        line-height: $row-height;
      }

      .index-column {
        flex: 0 0 50px;
        max-width: 50px;
        color: $theme-primary-grey;
        font-family: "Poppins-Semi-Bold";
        font-size: $font-11;
        padding: 0 15px 0 15px;
      }

      .group-column {
        cursor: pointer;
        flex: 0 0 270px;
        padding: 0 15px 0 15px;
      }

      .checkbox-column {
        flex: 0 0 150px;
        text-align: center;
        height: 100%;
        padding: 0 15px 0 15px;
      }
    }
  }

  .visible {
    display: flex;
    flex-direction: column;
    flex: 1;

    .datatable-header {
      width: 100% !important;
      background-color: $theme-white;
      display: flex;
      align-items: center;
      flex: 0 0 25px;
      border-bottom: 1px solid $theme-primary-grey;

      .datatable-header-inner {
        height: inherit;

        .datatable-header-cell {
          color: $theme-primary-grey;
          font-family: "Poppins-Semi-Bold";
          font-size: $font-11;
          padding: 0 15px 0 15px;
        }
      }
      .datatable-row-center{
        padding-top: 3px !important;
      }
    }

    .datatable-body {
      overflow: auto;

      .datatable-scroll {
        width: 100% ;
      }

      flex:1 1 auto;
      height: 0 !important;
      width: 100;

      .datatable-row-wrapper {
        width: auto !important;

        &:nth-child(2n) {
          background-color: $theme-white;
        }

        &:nth-child(2n+1) {
          background-color: $theme-table-odd-row-color;
        }

        .datatable-body-row {
          width: auto !important;

          .datatable-body-cell {
            padding: 0 15px 0 15px;

            .datatable-body-cell-label {
              height: inherit;

              div {
                display: flex;
                align-items: center;
                height: inherit;
                color: $theme-primary-grey;
                font-family: "Poppins-Semi-Bold";
                font-size: $font-11;
              }

              .disabledRow {
                color: $theme-input-placeholder;
              }
            }
          }
        }
      }
    }

    .datatable-footer {
      flex: 0 0 35px;
      align-items: center;
      display: none;

      .datatable-footer-inner {
        justify-content: end;

        .pagination-control {
          .page-link {
            padding: 6px 8px;
            color: $theme-primary-blue !important;
            border: 1px solid $theme-primary-blue !important;
            font-size: 9px;
            font-family: "Poppins-Semi-Bold";
            letter-spacing: normal;
          }

          .page-item.active .page-link {
            padding: 6px 8px;
            background-color: $theme-primary-blue !important;
            border-color: $theme-primary-blue !important;
            color: $theme-white !important;
            font-size: $font-9;
            letter-spacing: normal;
          }
        }
      }
    }
  }

}

// for table body row full width

@media screen and (min-width: 1537px) {
  .ngx-datatable.bootstrap {
    .visible {
      .datatable-body {
        .datatable-row-wrapper {
          width: -webkit-fill-available !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1307px) {
  .ngx-datatable.bootstrap.expandable {
    .visible {
      .datatable-scroll {
        width: inherit ;
      }

      .datatable-body-row {
        border-bottom: 1px solid $border-color !important
      }
    }
  }
}

// for table body row full width

//***ngx datatable***

//--- for confirm_unsaved popup---
.editPermission,
.activeInactive {
  .modal-dialog {
    max-width: 345px !important;
  }
}

//--- for accept request popup--
.acceptpopupwidth {
  .modal-dialog {
    max-width: 550px !important;
  }
}

.confirmedpopup {
  .modal-dialog {
    max-width: 410px !important;
  }
}


//--- for confirm_unsaved popup---

//--- for reset password request send to mail confirm start
.password_reset {
  .modal-dialog {
    max-width: 385px !important;
  }
}
//--- for reset password request confirm end

.pagination-control {
  .page-link {
    padding: 6px 8px;
    color: $theme-primary-blue !important;
    border: 1px solid $theme-primary-blue !important;
    font-size: 9px;
    font-family: "Poppins-Semi-Bold";
    letter-spacing: normal;
  }

  .page-item.active .page-link {
    padding: 6px 8px;
    background-color: $theme-primary-blue !important;
    border-color: $theme-primary-blue !important;
    color: $theme-white !important;
    font-size: $font-9;
    letter-spacing: normal;
  }
}

/*****custom checkbox*****/
.checkbox {
  &.checkbox-custom {
    $size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    line-height: 1.2;

    label {
      margin-top: 0;
      margin-bottom: 0;
      position: relative;
      margin-left: -$size;
      padding-left: $size;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        margin-left: 0;
        width: $size;
        height: $size;
        border-radius: 2px;
        border-color: $theme-primary-grey;
        transform: translateY(-50%);
        padding: 2px 0;
        font-size: 8px;
      }

      // &:read-only{
      //   cursor: default !important;
      //   pointer-events: none !important;
      // }
    }


    input[type=checkbox] {
      width: $size;
      height: $size;
      margin: 0;

      &:checked {
        &+label {
          &::before {
            content: '\e64c';
            border-color: $theme-primary-blue;
            color: $theme-primary-blue;
            color: $theme-white;
            background: $theme-primary-blue;
          }
        }
      }

      &.action-disabled {
        &+label {
          cursor: not-allowed;
          // &::before {
          //   cursor: not-allowed;
          //   background: $theme-disabled-color;
          //   color: $theme-primary-grey;
          //   border:1px solid $theme-primary-grey;
          // }
        }
      }

      &:read-only {
        &+label {
          cursor: not-allowed;
        }
      }
    }

    &.checkbox-dash {
      input[type=checkbox]+label::before {
        content: '\268a';
        font-size: 10px;
        line-height: 0.9;
      }
    }
  }

  & {
    .action-disabled {
      label {
        cursor: not-allowed;
      }
    }
  }
}

/*****end custom checkbox*****/

// ..........form settings.......
.form-setting-body li {
  app-checkbox .form-check {
    label {
      padding-left: 10px !important;
    }
  }
}

.language-ul {
  .language-li {
    app-input {
      width: calc(100% - 60px) !important
    }
  }
}


.tooltip {
  .tooltip-inner {
    margin: 0 10px !important;
    color: $theme-primary-grey;
    background-color: white;
    border: 1px solid $theme-input-placeholder;
    border-radius: 7px;
    font-family: 'Poppins-Regular';
    font-size: $font-10;
    text-align: left;
    // position: relative !important;
    // top: -20px !important;
    // left: 0 !important;
  }
}

.tooltip-arrow {
  display: none !important;
}

.organization_qbo_id{
  .form-control{
    padding-right: 5px !important;
  }
}

// ........form settings.......

.checkbox {
  app-checkbox {
    .form-check {
      padding-left: 8px !important;
    }
  }
}

svg:focus {
  outline-color: transparent !important;
}

.form-control:focus {
  color: $theme-primary-grey !important;
}

.form-control:not(.timePicker) {
  line-height: normal !important;
}


.form-control.timePicker {
  line-height: initial !important;
}

.action-disabled {
  cursor: not-allowed !important;
}

.addEditUserRole {
  .modal-dialog {
    max-width: 306px !important;
  }
}

.disconnect_quickbooks {
  .modal-dialog {
    max-width: max-content !important;
  }
}



.reqSubmitted {
  .modal-dialog {
    max-width: 350px !important;
  }
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content>li .sidebar-link::after {
  height: 100%;
  top: 51%;
}

.small-buttons {
  app-button {
    button {
      height: 1.75rem !important;
      border-radius: 10px !important;
    }
  }
}

// for schedule availibility

.unsaved-changes {
  .modal-dialog {
    max-width: 354px !important;
  }
}
.cancel-changes{
  .modal-dialog {
    max-width: 400px !important;
  }
}

.slider-bg-disabled {
  app-switch {
    .switch_toogle {
      span {
        background-color: $theme-disabled-color;
      }
    }

  }
}

// for schedule availibility

//for organization deactivated popup start
.deactivate_organization , .request_decline_pop_up{
  .modal-dialog {
    max-width: 405px !important;
  }
}
//for organization deactivated popup end

//for Usermanagement deactivated popup start
.activate_deactivate_user{
  .modal-dialog {
    max-width: 400px !important;
  }
}
//for Usermanagement deactivated popup end
@media only screen and (max-width: 575px) {
  .hide-dropdown-md {
    display: none !important;
  }.show-dropdown-md{
    display: flex !important;
  }.w-9rem{
    width: 9rem !important;
  }.top-width-md-70{
    top: 70% !important;
  }.tab-card-md{
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(8, 21, 66, 0.20);
    overflow: hidden;
    margin: 0px 8px 0px 8px;
  }
}

@media only screen and (max-height: 867px){
  .top-height-md-70{
    top: 65% !important;
  }
}
// User Management Responsive region
@media (min-width: 992px) and (max-width: 1384px) {
  .custom-search-col {
      width: 63%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .custom-search-col {
      width: 60%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .custom-search-col {
    width: 48% ;
  }
}

@media (max-width: 575px) {
  .show-checkbox-div {
    display: flex !important;
  }}
// User Management Responsive region end
//app-calender from User Settings start-----
app-calender {
  display: block;
  height: 100%;

  .calender {
    .selected-day {
      background-color: transparent !important;
      color: $theme-primary-grey;
      border: 1px solid $theme-primary-orange-red !important;
    }

    .cal-has-events {
      background-color: $theme-input-placeholder !important;

      .cal-day-number {
        color: $theme-white !important;
      }
    }

    .card {
      box-shadow: none !important;
    }

    .card:hover {
      box-shadow: none !important;
    }

    .card-body {
      .calender-main {

        .cal-events,
        .cal-day-badge {
          display: none;
        }

        .cal-days {
          div {
            mwl-calendar-open-day-events {
              display: none !important;
            }

          }

          div:last-child {
            .cal-cell-row {
              .cal-has-events:first-child {
                border-bottom-left-radius: 14px;
              }

              .cal-has-events:last-child {
                border-bottom-right-radius: 14px;
              }

              border: 0 !important;

              .selected-day:first-child {
                border-bottom-left-radius: 14px;
              }

              .selected-day:last-child {
                border-bottom-right-radius: 14px;
              }

              .cal-cell:first-child:hover {
                border-bottom-left-radius: 10px;
              }

              .cal-cell:last-child:hover {
                border-bottom-right-radius: 10px;
              }
            }
          }
        }

        .cal-cell-row {
          text-transform: capitalize;
          border-right-color: $theme-input-placeholder !important;

        }

        .cal-month-view {
          .cal-days {
            .cal-cell {
              border: 1px solid transparent;
            }

            .cal-cell:hover {
              border-color: $theme-primary-grey !important;
              background-color: transparent;
            }
          }
        }

        .cal-month-view .cal-day-cell:not(:last-child) {
          border-right-color: $theme-input-placeholder;
        }

        mwl-calendar-month-cell {
          min-height: 50px !important;

          .cal-cell-top {
            min-height: 50px !important;

            span {
              .cal-day-number {
                color: $theme-primary-grey;
                font-size: $font-12;
                margin: 0.5rem;
                font-family: "Poppins-Semi-Bold";
              }
            }
          }

          .cal-day-number {
            opacity: 1;
          }
        }

        .cal-out-month {
          .cal-cell-top {
            .cal-day-number {
              color: $theme-disabled-color !important;
            }
          }
        }

        // .cal-month-view .cal-cell-top {
        //   min-height: 79px;
        // }

        .cal-month-view .cal-day-cell.cal-today {
          .cal-day-number {
            color: $theme-primary-blue;
          }

          background-color:$theme-white;
          border: 1px solid $theme-primary-blue !important;
        }

        .cal-month-view .cal-day-cell {
          // border: 1px solid $theme-input-placeholder;
          border-top: 0;
        }

        .cal-month-view .cal-header .cal-cell {
          border-right: 1px solid $theme-primary-blue;
          font-family: "Poppins-Regular";
          font-weight: normal;
          padding: 0;
        }

        .cal-month-view .cal-header .cal-cell:hover {
          background-color: transparent;
        }

        .cal-month-view .cal-header .cal-cell:last-child {
          border-right: 0;
        }

        mwl-calendar-month-view {
          .cal-month-view .cal-days .cal-cell-row {
            border-bottom-color: $theme-input-placeholder;
          }

          .cal-month-view .cal-days .cal-cell-row:last-child {
            border-bottom-color: transparent !important;
          }

          .cal-cell-row {
            .cal-cell {
              color: $theme-primary-blue;
            }
          }

          .cal-header {
            border: 1px solid $theme-primary-blue;
            border-right: 1px solid $theme-primary-blue !important;
            border-radius: 8px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            font-size: $font-12;
          }

          .cal-days {
            border-top: 0;
            border-bottom: 1px solid $theme-input-placeholder;
            border-right: 1px solid $theme-input-placeholder;
            border-left: 1px solid $theme-input-placeholder;
            border-radius: 15px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            font-size: $font-12;
            overflow: hidden;
          }
        }

      }

      .current-day-date {
        font-family: "Poppins-Semi-Bold";
        color: $theme-primary-grey;
        letter-spacing: normal;
      }

      i {
        font-size: $font-25;
        color: $theme-primary-grey;
      }

      .nav.nav-tabs {
        padding: 0 20px;

        .active {
          a {
            color: $theme-primary-blue !important;
          }

          border-bottom: 2px solid $theme-primary-blue !important;

          & :focus {
            border-bottom: none;
            color: $theme-primary-blue !important;

            a {
              color: $theme-primary-blue !important;
            }
          }
        }

        li {
          a {
            font-family: "Poppins-Semi-Bold";
            font-size: $font-12;
            letter-spacing: normal;
            padding: 0 20px;
            color: $theme-primary-grey;
            height: 25px;

            &.show {
              color: $theme-primary-blue;
              border-bottom: 3px solid $theme-primary-blue;
            }

            &:hover {
              color: $theme-primary-blue;
            }
          }
        }
      }
    }

    .calender-list {
      span {
        font-family: "Poppins-Semi-Bold";
        font-size: $font-12;
        color: $theme-primary-grey;
      }

      border: 1px solid $theme-primary-blue;
      border-radius: 10px;

      .list-del-edit {
        background-color: $theme-disabled-color;
        border-top: 1px solid $theme-primary-blue;
        ;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .svg-del {
      background-color: $theme-primary-orange-red;
      border-radius: 10px;
    }

    .Blockout-buttons {
      .add-Blockout {
        color: $theme-primary-blue;
        border: 1px solid $theme-primary-blue;
        border-radius: 10px;
        font-size: $font-12;
        font-family: "Poppins-Semi-Bold";
      }

      .remove-Blockout {
        color: $theme-primary-orange-red;
        border: 1px solid $theme-primary-orange-red;
        border-radius: 10px;
        font-size: $font-12;
        font-family: "Poppins-Semi-Bold";
      }
    }
  }
}

//app-calender from User Settings end-----


// app-calender from appointment and scheduling start-----
app-calendar {
  .calender-appointment {
    height: calc(100% - 50px);

    .card-shadow {
      box-shadow: 0 0 8px rgba(8, 21, 66, 0.15) !important;
      border-radius: $theme-border-radius-8px !important;
    }

    .card-shadow-list {
      box-shadow: none;
      border-radius: $theme-border-radius-8px !important;
    }
  }
}

@media (max-width: 760px) {
  .hide-button-md {
    display: none !important;
  }
  .show-button-md {
    display: flex !important;
  }

  .scheduling_card_header_info{
    border-bottom: 0 !important;
  }
}
// app-calender from appointment and scheduling end

//popUp custom width start //
.services_popUp {
  max-width: 415px !important;
}

//end

// notification css

.border-left-6 {
  border-left-width: 6px !important;
}

.border-left-blue {
  border-left: 6px solid $theme-primary-blue !important;
}

.border-left-grey {
  border-left: 6px solid $theme-primary-grey !important;
}

.notification-dropdown {
  height: auto;
}

.notification-dropdown.onhover-show-div {
  width: 350px !important;
  right: 10px !important;
  box-shadow: 0 0 2px rgb(4 123 188) !important;



  ul {
    height: auto !important;
    overflow: auto;
    margin: 2rem 1.4rem !important;
    padding: 0 !important;

    li {
      border-top: 1px solid $border-light-blue;
      border-right: 1px solid $border-light-blue;
      border-bottom: 1px solid $border-light-blue;
      padding: 6px 9px !important;

      p:first-child {
        text-align: start;

        span {
          font-family: "Poppins-Semi-Bold";
          font-size: $font-10;
          color: $theme-primary-blue;
        }
      }

      p:last-child {
        text-align: start;

        span {
          font-family: "Poppins-Regular";
          font-size: $font-10;
          color: $theme-primary-grey;
          // word-break: break-all;
        }
      }

    }
  }

  .notification-footer-contain {
    margin-bottom: 2rem !important;
    padding: 0 2rem !important;

    .notification-footer {
      font-family: "Poppins-Semi-Bold" !important;
      font-size: $font-12 !important;
      color: $theme-primary-blue !important;
    }
  }
}

// @media screen and (max-width: 575.98px){
//   .notification-dropdown.onhover-show-div{

//     width: 300px !important;
//   }
// }

// notification sidebar custom and overight scss start 
.offcanvas {
  position: fixed !important;
  top: 83px !important;
  z-index: var(--bs-offcanvas-zindex) !important;
  color: var(--bs-offcanvas-color);
  background-color: var(--bs-offcanvas-bg) !important;
  background-clip: padding-box !important;
  border-top-left-radius: 1.3rem;
  border-bottom-left-radius: 1.3rem;
  box-shadow: 0px 0px 10px 0px rgba(226, 226, 226, 1);
  max-width: 22rem !important;
  border: none !important;
}

.offcanvas-backdrop.fade {
  opacity: 0 !important;
}

// notification sidebar custom and overight scss end 

// #filter and update-apoointment-record popup start
.update-apoointment-record {
  .modal-dialog {
    max-width: 550px !important;
  }
}

.filter-sidebar{
  #ICON_-_drop-2{
    color: $theme-primary-grey !important;
  }
}

.filter-popup {
  .modal-dialog {
    max-width: 600px !important;
    min-height: calc(100% - 76px);
  }
}

//#filter and update-apoointment-record popup end
//stable scrollbar start 
.stableScroll {
  scrollbar-gutter: stable both-edges;
}

//stable scrollbar end

.Request-Category {

  .filterDropdown,
  .variant-dropdown {
    .drop_Options {
      overflow-y: visible !important;
    }
  }

  .focus-none:focus {
    box-shadow: none;
    color: $theme-primary-blue !important;
  }

  .tooltip {
    .tooltip-inner {
      position: relative !important;
      right: 25px !important;
    }
  }
}

.empty-row {
  display: none;
}


@media only screen and (min-width: 1281px) {
  .requestlistWidth {
    .col-xxl-3 {
      flex: 0 0 auto;
      width: 22% !important;
    }

    .col-xxl-9 {
      flex: 0 0 auto;
      width: 76% !important;
    }
  }

}

.filterbuttondevice {
  .modal-dialog {
    min-height: calc(100% - (-0px));
  }
}

input[type="time"] {
  padding: 5px;
}

input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  font-size: 11px;
}

// full-calendar start----
full-calendar {

  .fc-header-toolbar {
    text-transform: capitalize;
    margin-bottom: 1em !important;
    display: flex;
    flex-wrap: nowrap !important;

    .fc-toolbar-chunk {
      cursor: pointer !important;
      display: flex;
      flex-wrap: nowrap;

      .fc-today-button {
        border-radius: 8px !important;
        border: 1px solid $theme-primary-blue !important;
        opacity: 1;
        color: $theme-white !important;
        background-color: $theme-primary-blue !important;
        text-transform: capitalize !important;

      }

      .fc-toolbar-title {
        font-size: 14px;
        font-family: "Poppins-Semi-Bold" !important;
        color: $theme-primary-blue !important;
        cursor: context-menu;
      }

      .btn-group {
        border-radius: 8px !important;

        .fc-prev-button {
          padding: 1rem 1rem 1rem 2rem !important;
          background-image: url('../src//assets/svg/cal-prev-icon.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 14px;
        }

        .fc-next-button {
          padding: 1rem 1rem 1rem 2rem !important;
          background-image: url('../src//assets/svg/cal-next-icon.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 14px;

        }

        button {
          color: $theme-white !important;
          border: 1px solid $theme-primary-blue !important;
          background-color: $theme-primary-blue !important;
          padding-left: 15px !important;
          padding-right: 15px !important;
          text-transform: capitalize !important;

        }

        .btn-primary:not([disabled]):not(.disabled).active:hover,
        .btn-primary:not([disabled]):not(.disabled).active:focus,
        .btn-primary:not([disabled]):not(.disabled).active:active,
        .btn-primary:not([disabled]):not(.disabled).active.active {
          color: $theme-primary-blue !important;
          ;
          background-color: #fff !important;
          border: 1px solid $theme-primary-blue !important;
        }
      }
    }
  }

  .fc-popover {
    max-height: 250px;

    .fc-popover-header {
      display: none !important;
    }

    .fc-popover-body {
      max-height: 250px;
      font-family: "Poppins-Regular" !important;
      font-size: 12px !important;
      overflow-y: scroll;
      border: 1px solid $border-light-gray !important;
      border-radius: 10px;

      .fc-daygrid-event-harness a {
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer !important;
        text-wrap: wrap !important;
        border-radius: 10px;
      }

      .fc-daygrid-block-event{
        background:transparent;
        border: 0;
      }
      .fc-daygrid-block-event:hover {
        background: rgba(0, 0, 0, 0.1);
    }
    }

    ::-webkit-scrollbar {
      width: 0px !important;
    }
  }

  .fc-dayGridMonth-view {
    table {
      thead{
        .fc-col-header {
          width: 100% !important;
        }
      }
      tbody{
        .fc-daygrid-body{
          width: 100% !important;
          table{
            width: 100% !important;
          }
        }
      }
     
      border-radius: 10px;

      ::-webkit-scrollbar {
        width: 0px;
      }


      // border-top: 1px solid !important;

      thead {
        .fc-scrollgrid-section-header {
          th {
            text-transform: capitalize;
            border: 1px solid $theme-primary-blue;
            border-top-right-radius: 8px !important;
            border-top-left-radius: 8px !important;
          }
        }

        .fc-col-header-cell {
          padding: 8px 0 8px 0;

          .fc-col-header-cell-cushion {
            text-transform: capitalize;
            color: $theme-primary-blue !important;
            font-family: "Poppins-Semi-Bold" !important;
            font-weight: normal !important;
            font-size: 12px;
          }

        }
      }

      tbody {
        .fc-daygrid-block-event{
          background:transparent;
          border: 0;
        }
        .fc-daygrid-block-event:hover {
          background: rgba(0, 0, 0, 0.1);
      } 
        .fc-event {
          cursor: pointer !important;
       }

        .fc-event-main {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .fc-daygrid-day-bottom {
          .fc-daygrid-more-link {
            float: right;
          }

          color: $theme-primary-blue !important;
          font-family: "Poppins-Semi-Bold" !important;
          font-size: 12px !important;
        }

        font-family: "Poppins-Regular" !important;
        font-size: 12px !important;

        .fc-scrollgrid-section-body {
          td {
            border-bottom-right-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
          }
        }

        tr {
          .fc-daygrid-body {
            table {
              tbody {
                tr {

                  // border: 2px solid red !important;
                  .fc-day-other {
                    .fc-daygrid-day-top {
                      opacity: 1 !important;
                    }

                    .fc-daygrid-day-number {
                      color: #E2E2E5 !important;
                    }
                  }

                  .fc-daygrid-day-number {
                    color: $theme-primary-grey !important;
                    font-size: 12px !important;
                    font-family: "Poppins-Semi-Bold" !important;

                    @media only screen and (max-width:576px) {
                      font-size: 8px !important;

                    }
                  }

                  .fc-daygrid-day.fc-day-today {
                    .fc-daygrid-day-frame {
                      border: 1px solid $theme-primary-blue;
                      border-radius: 0px;
                    }


                  }

                  .fc-daygrid-day {
                    .fc-daygrid-day-frame {
                      border: 1px solid transparent;
                    }
                  }

                  .fc-daygrid-day:hover {
                    .fc-daygrid-day-frame {
                      // border-color: $theme-primary-orange-red;
                      border-color: $theme-primary-grey;
                    }
                  }

                }
              }
            }

          }
        }
      }

    }
  }

  // week-view 
  .fc-timeGridWeek-view,
  .fc-timeGridDay-view {

    table {
      // .fc-scroller-harness{
      //   .fc-scroller,.fc-daygrid-body,.fc-timegrid-body{
      //     .fc-col-header  {
      //       width: 100% !important;
      //     }
      //     overflow: scroll !important;
      //     width: 100% !important;
      //     table{
      //       width: 100% !important;
      //     }
      //   }
        
      // }

      // border-radius: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      // width: 625px !important;
      thead {
        .fc-scrollgrid-section-header {
          th {
            border: 1px solid $theme-primary-blue;
            border-top-right-radius: 8px !important;
            border-top-left-radius: 8px !important;
          }
        }

        .fc-col-header-cell {
          padding: 8px 0 8px 0;

          .fc-col-header-cell-cushion {
            text-transform: capitalize;
            color: $theme-primary-blue !important;
            font-family: "Poppins-Semi-Bold" !important;
            font-weight: normal !important;
            font-size: 12px !important;
          }
        }
      }

      tbody {
        .fc-timegrid-slot-minor {
          border-top-style: solid;
        }

        .fc-scrollgrid-section-body {
          ::-webkit-scrollbar {
            width: 0px;
          }
        }

        .fc-timegrid-slot-lane {
          background-color: transparent !important;
        }

        .fc-event-main {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        font-family: "Poppins-Semi-Bold" !important;
        font-size: 12px !important;

        .fc-timegrid-axis-cushion {
          color: $theme-primary-blue !important;
        }

        tr {
          text-transform: capitalize;

          td {
            tbody {
              .fc-timegrid-slot-label-cushion {
                color: $theme-primary-grey !important;

              }

              .fc-timegrid-event-harness-inset .fc-timegrid-event,
              .fc-timegrid-event.fc-event-mirror,
              .fc-timegrid-more-link {
                background-color: white;
                border: none !important;
                box-shadow: none;

              }

              .fc-event-main {
                border-radius: 5px !important;
                cursor: pointer !important;
              }

            }
          }
        }


      }
    }
  }

  //list view
  .fc-listMonth-view {
    font-family: "Poppins-Semi-Bold" !important;
    font-size: 12px !important;
    border-radius: 10px !important;
    border: 1px solid $theme-primary-blue !important;
    overflow: hidden !important;

    ::-webkit-scrollbar {
      width: 0px;
    }

    th {
      border-radius: 10px !important;
    }

    tbody {
      .fc-list-event-time {
        color: transparent !important;
        display: none !important;
      }

      .fc-list-day:first-child .fc-list-day-cushion {
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px !important;
      }
      
      .fc-list-day .fc-list-day-cushion {
        text-transform: capitalize;
        background-color: #F1F1F1 !important;
        color: $theme-primary-blue !important;
      }

      .fc-list-sticky {
        border-radius: 10px !important;
      }

      tr {
        th {
          z-index: 3;
        }

        th,
        td {
          .fc-list-event-dot {
            display: none !important;
          }

          border-bottom: 1px solid $theme-primary-blue !important;
        }
      }

      .fc-event-main {
        margin-left: -14px;
        cursor: pointer !important;
      }


    }

    .fc-list-empty-cushion {
      color: $theme-primary-grey !important;
    }
  }

  @media only screen and (max-width: 658px) {
    .fc-header-toolbar {
      text-transform: capitalize;
      flex-direction: column;
      margin-bottom: 70px !important;
    }

    .fc-today-button {
      position: absolute;
      right: 0;
      width: 100px;
    }

    .btn-group {
      position: absolute;
      left: 0;
    }

    .fc-toolbar-title {
      margin-top: 40px !important;
    }

    .btn-group:last-child {
      margin-top: 10px;
      width: 100%;
    }

  }

  ::-webkit-scrollbar-track {
    margin: 6px 0px;
  }
}

// // Calendar Extra CSS :-
.fc-scrollgrid-section{
  &.fc-scrollgrid-section-header {
    th {
      text-transform: capitalize;
      border: 1px solid $theme-primary-blue;
      border-top-right-radius: 8px !important;
      border-top-left-radius: 8px !important;
    }
  }
}
.fc-timegrid-divider {
  padding: 0 !important;
  border: none;
}
.fc .fc-scrollgrid table {
  border-left-style: hidden;
  border-right-style: hidden;
  border-top-style: hidden;
  border-bottom-style: hidden;
}
.fc .fc-scroller-liquid-absolute{
    scrollbar-width: thin;
}
// full-calendar end---

//dashboard--

@media screen and (min-width: 1830px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.60) translate(-150px, -90px);
      }

      height:400px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 1685px) and (max-width: 1829px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.55) translate(-200px, -90px);
      }

      height:400px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 1450px) and (max-width: 1684px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.45) translate(-250px, -90px);
      }

      height:400px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1449px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.35) translate(-290px, -90px);
      }

      height:400px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.35) translate(-290px, -90px);
      }

      height:400px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1025px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.5) translate(-230px, -90px);
      }

      height:400px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 450px) and (max-width: 767px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.49) translate(-230px, -90px);
      }

      height:400px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 401px) and (max-width: 449px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.35) translate(-290px, -200px);
      }

      height:250px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 371px) and (max-width: 400px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.3) translate(-295px, -200px);
      }

      height:250px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 360px) and (max-width: 370px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.29) translate(-325px, -200px);
      }

      height:250px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 359px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.35) translate(-250px, -200px);
      }

      height:250px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

@media screen and (min-width: 270px) and (max-width: 319px) {
  .jvectormap-container {
    svg {
      g {
        transform: scale(0.204) translate(-350px, -200px);
      }

      height:175px !important;
      width: 100% !important;

    }

    .jvectormap-zoomin,
    .jvectormap-zoomout {
      display: none !important;
    }
  }
}

// added for dashboard page
.country-list-border {
  border: 1px solid $theme-primary-grey;
  border-radius: 10px;

  .country-list {
    height: 400px;
    overflow: auto;
  }
}

@media screen and (min-width: 270px) and (max-width: 767px) {
  .country-list-border {
    border: 1px solid $theme-primary-grey;
    border-radius: 10px;

    .country-list {
      height: 150px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 10px !important; // Don't remove
      }
    }
  }

}

// added for dashboard page

//dashboard--


//manage-organization 
.tooltip-delIcon {
  .tooltip {
    .tooltip-inner {
      color: $theme-primary-grey !important;
      background-color: white !important;
      border: 1px solid $theme-input-placeholder !important;
      border-radius: 7px;
      font-family: 'Poppins-Regular' !important;
      font-size: $font-10;
      top: 6px !important;
      position: relative !important;
    }
  }
}

// for New Date picker
.newDatePicker {
  ngb-datepicker {

    // mandatory for show proper date monthwise don't remove it
    ngb-datepicker-month {
      .ngb-dp-week:not(.ngb-dp-weekdays):nth-child(2) {
        justify-content: flex-end;
      }

      .ngb-dp-week:not(.ngb-dp-weekdays):nth-last-child(2) {
        justify-content: flex-start;
      }
    }

    // mandatory for show proper date monthwise don't remove it

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    border:none;

    .ngb-dp-header {
      width: 100%;

      .ngb-dp-arrow {
        justify-content: center;
      }

      .btn-link {
        color: $theme-primary-blue;

        .ngb-dp-navigation-chevron {
          border-width: 2.38px 2.38px 0px 0px;
        }
      }

      .ngb-dp-navigation-select {
        gap: 10px;

        .form-select {
          outline: none;
          box-shadow: none;
          font-family: "Poppins-Semi-Bold";
          font-size: $font-12;
          color: $theme-primary-blue;
          border-radius: 50px;
          border-color: $theme-primary-blue;
          background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="none" stroke="%23047BBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m2 5 6 6 6-6"/%3e%3c/svg%3e') !important;
        }
      }
    }

    .ngb-dp-months {
      .ngb-dp-month {
        .ngb-dp-month-name {
          font-family: "Poppins-Semi-Bold";
          font-size: $font-12;
          color: $theme-primary-grey;
          height: 2rem;
          line-height: 2rem;
          text-align: center;
        }

        .ngb-dp-weekdays,
        .ngb-dp-week {
          border-bottom: none !important;
          border-radius: 0;

          .ngb-dp-weekday:first-of-type {
            color: $theme-primary-orange-red;
            font-family: "Poppins-Semi-Bold";
            font-style: normal;
          }

          .ngb-dp-weekday:not(:first-of-type) {
            color: $theme-primary-blue;
            font-family: "Poppins-Semi-Bold";
            font-style: normal;
          }

          .ngb-dp-day {
            font-family: "Poppins-Regular";
            font-size: $font-11;
            color: $theme-primary-grey;

            .custom-day {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 2rem;
              width: 2rem;

              &:hover:not(.faded, .range) {
                border-radius: 50% !important;
              }
            }

            .custom-day.range,
            .custom-day:hover {
              background-color: $theme-primary-blue;
              color: $theme-white;
            }

            >.custom-day.range {
              // &:not(.faded) {
              //   background: $theme-primary-blue;
              //   border-radius: 50% 0 0 50%;
              // }

              &.range-start:not(.faded) {
                background: $theme-primary-blue;
                border-radius: 50% 0 0 50%;
              }

              &.range-end:not(.faded) {
                background: $theme-primary-blue;
                border-radius: 0 50% 50% 0;
              }

              &.range-start.range-end:not(.faded) {
                background: $theme-primary-blue;
                border-radius: 50%;
              }

              &.faded {
                background: $theme-primary-light-skyblue;
                border-radius: 0;
              }
            }


            &.ngb-dp-today {
              color: $theme-primary-orange-red;
            }
          }
        }
      }
    }

  }
}

@media (max-width: 768px) {
  .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
    width: 1.3rem !important;
    height: 1.6rem !important;
  }
  .newDatePicker ngb-datepicker .ngb-dp-header .ngb-dp-navigation-select .form-select {
    font-size: 10px;
    margin: 0px -6px 1px 0px;
  }
  .newDatePicker ngb-datepicker .ngb-dp-months .ngb-dp-month .ngb-dp-weekdays .ngb-dp-day .custom-day, .newDatePicker ngb-datepicker .ngb-dp-months .ngb-dp-month .ngb-dp-week .ngb-dp-day .custom-day{
    width: 1rem  !important;
  }
  .ngb-dp-navigation-select .selects{
    display: flex !important;
    flex: 1 1 9rem !important;
  }
  .edit-group-w95{
    width: 95%;
  }
}

/* Adjust tooltip positioning */
.ngb-tooltip {
  z-index: 9999;
  /* Ensure tooltips appear above other elements */
  position: absolute;
  /* Position tooltips absolutely */
}

// custom ngb-timepicker
.time-picker-custom {
  fieldset {
    border: 1px solid $theme-primary-grey !important;
    border-radius: 10px !important;

    .ngb-tp {
      padding: 10px !important;

      .ngb-tp-input-container {
        width: 3em;

        .btn-link {
          color: $theme-primary-blue;

          .ngb-tp-chevron:before {
            border-style: solid;
            border-width: 0.2em 0.2em 0 0;
            content: "";
            display: inline-block;
            height: 0.9em;
            left: 0.05em;
            position: relative;
            top: 0.15em;
            transform: rotate(-45deg);
            vertical-align: middle;
            width: 0.9em;

            &:hover,
            &:focus {
              color: $theme-primary-blue;
            }
          }

          .ngb-tp-chevron.bottom:before {
            top: 0.1em;
            transform: rotate(135deg) !important;
          }
        }

        input {
          border-color: $theme-primary-grey !important;
          font-size: $font-14;
          color: $theme-primary-grey !important;
          font-family: "Poppins-Regular";
          padding: 12px;
          box-shadow: none;
          // pointer-events: none;
        }

        input::placeholder {
          font-size: $font-14;
        }
      }

      .ngb-tp-spacer {
        width: 0.8em;
      }

      .ngb-tp-meridian {
        button {
          border-color: $theme-primary-grey !important;
          font-size: $font-14;
          color: $theme-primary-grey !important;
          font-family: "Poppins-Regular";
          padding: 12px;
          box-shadow: none;
          width: 48px;

          &:hover,
          &:focus {
            background-color: $theme-white !important;
            color: $theme-primary-grey !important;
          }
        }
      }
    }
  }
}


.time-picker-custom {
  &.time_range_picker{
    fieldset {
      .ngb-tp {
        padding:6px 8px !important; //
  
        .ngb-tp-input-container {
          width: 2em; //
  
          .btn-link {
            padding: 0; //
            font-size: 10px; //
          }
  
          input {
            font-size: $font-11;//
            padding:3px 5px; //
          }
  
          input::placeholder {
            font-size: $font-11 //;
          }
        }
  
        .ngb-tp-spacer {
          width: 0.4em; //
        }
  
        .ngb-tp-meridian {
          button {
            font-size: $font-11;//
            padding:3px 5px;//
            width: 32px; //
          }
        }
      }
    }
  }
}

// custom ngb-timepicker

//common country code dropdown 
.commonInputContainer{
    .country-code{
      .select-box{
        width: 99.75px;
      }
    }
  }
//

.contryCodeBorderFocus{
  .select-box{
    border: 1px solid $theme-primary-orange-red !important;
  }
}

.disableCheckboxColor{
  .container.disabledWhenHardcode{
    border: 1px solid $theme-primary-grey !important;
  }
}
#scheduledOption_tooltipId{
  position: absolute;
  margin-top: 2px;
  .scheduledOption_tooltip{
    &.tooltip{
    .tooltip-inner{
      max-width: 258px !important;
      margin: 2px 0px 0px -2px !important;
      width: max-content;
      font-size: 10px;
      // text-align: justify;
      } 
    }
  }
}

.sidebar-main{
  app-languages{
      .more_lang.active {
        width: fit-content !important;
        top: 142px !important;
    }
  }
}

// start form setting interpreter rates  input overright css
.interpreter_rates_input{
  .commonInputContainer {
    .xtraSmallInput{
      padding-left: 15px !important;
    }
  }
  .courts_doller_symbol{
    left: 7px;
    color: $theme-primary-blue !important;
    z-index: 1;
    &.is_disabled{
      color: $theme-primary-grey !important;
    }
  }
  .non_courts_doller_symbol{
    left: 7px;
    color: $theme-primary-orange-red !important;
    z-index: 1;
    &.is_disabled{
      color: $theme-primary-grey !important;
    }
  }
  .courts_field_disabled{
    .commonInputContainer {
      .xtraSmallInput{
        color: $theme-primary-blue !important;
      }
    }
  }
  .non_courts_field_disabled{
    .commonInputContainer {
      .xtraSmallInput{
        color: $theme-primary-orange-red !important;
      }
    }
  }
}
// end form setting interpreter rates  input overright css


// login page remember me tooltip 



.login-card{
  .logo{
    .login_ceilo_logo{
      @media only screen and (max-width:576px) {
        width: 250px !important;
      }
      @media only screen and (max-width:350px) {
        width: 200px !important;
      }
    }
  }
  .rememberMe_tooltip_svg{
    position: relative;
    &.tooltip{
    .tooltip-inner{
      position: absolute;
      top: 5px;
      left: -187px;
      max-width: 310px !important;
      margin: 2px 0px 0px -2px !important;
      width: max-content;
      font-size: 10px;
      @media only screen and (max-width:576px) {
        left: -210px;
      }
        @media only screen and (max-width:430px) {
          left: -250px;
        }
        @media only screen and (max-width:367px) {
          max-width: 200px !important;
          left: -170px;
        }
      } 
    }
  }
}
// login page remember me tooltip 

//usermanagement deactive user start
.deactivate_user{
  .deactivate_user_radiobuttons{
    label{
      margin: 0;
      font-family:"Poppins-Semi-Bold" !important;
    }
  }
}
//usermanagement deactive user end

// otp inputs digits make center align start
.otp_inputs{
  .commonInputContainer{
    .bigInput {
      text-align: center !important;
    }
  }
}
//  otp inputs digits make center align end 


// processing loader start 
.processing_loader {
  position: relative;

  >span {
    color: transparent !important;
    visibility: hidden;
    pointer-events: none;
  }

  // pointer-events: none;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    content: '\f1ce';
    color: #fff;
    font: normal 400 1.275em/1 FontAwesome, sans-serif;
    animation: rotating 2s infinite linear;
    // animation: fa-spin 2s infinite linear;
    width: 0;
    margin: 0 auto;
    pointer-events: none;
  }
  &:is(div){
    &:after{
      color: $theme-primary-blue;
    }
  }
}

/* Loading animation on button */
@keyframes rotating {
  0% {
    transform: rotate3d(0,0,1,0);
  }
  100% {
    transform: rotate3d(0,0,1,360deg);
  }
}

// processing loader end

// For Modal to take full height in Mobile Screen 
@media screen and (max-height: 805px) {
  .createEditRequestForm {
    overflow: visible !important;
    .height-496px {
      height: 400px !important;
    }
  }
  .addEditUserForm{
    overflow: visible !important;
    .md-height-420{
      height: 420px !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .assignInterpretersForm{
    overflow: visible !important;
    .md-height-400{
      height: 375px !important;
      overflow-x: hidden;
    }
  }
  .filterbuttondevice{
    .shadow-right{.theme-form{.input-section{
      height: calc(100% - 40px) !important;
    }}}
    .md-height-475{
      height: 475px !important;
    }
  }
}
// For Request List-Filter Modal full view
@media screen and (max-width: 767px){
  .filterbuttondevice{
    overflow: visible !important;
    .md-height-475{
      height: 100%;
    }
  }
}
// For Report filter modal full view
@media screen and (max-width: 767px){
  .filter-popup {
    overflow: visible !important;
    .modal-body {
      height: 32rem;
      overflow: auto;
    }
  }
}
@media screen and (max-height: 602px){
  .filter-popup {
    overflow: visible !important;
    .modal-body {
      height: 27rem;
      overflow: auto;
    }
  }
}

// For Making Appointment-Schedueling Call-Link Input Border Red
.call-link-error-border-red .form-control {
  border: 1px solid $theme-primary-orange-red !important;
}
.call-link-error-border-red .form-control:focus {
  border: 1px solid $theme-primary-orange-red !important;
}
.call-link-info-icon-stroke-red path {
  fill: $theme-primary-orange-red !important; 
}